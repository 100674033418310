import { Suspense, lazy } from "react";
import SkeletonWL from "../Components/Skeleton/Withoutlogin";

export const lazyComponent = (importStatement) => {
  const LazyComponent = lazy(importStatement);
  return (props) => (
    <Suspense fallback={<SkeletonWL />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
