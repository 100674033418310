export const groupProgramsByCategory = (programs) => {
  return programs.reduce((acc, program) => {
    const { category } = program;
    if (!acc[category.toLowerCase()]) {
      acc[category.toLowerCase()] = [];
    }
    acc[category.toLowerCase()].push(program);
    return acc;
  }, {});
};
