import React, { Suspense, useState } from "react";
import Rcta from "../cta/request_a_call_back";
import Wrapper from "../Wrappers/Wrapper";

import {
  Hero,
  Info,
  Intro,
  PlacementSupport,
  Programs,
  Stats,
  Testimonials,
} from "./";
import Verticals from "./Vertical Page/Vertical";

const Home = () => {
  const [cta, setCta] = useState(false);
  const RcbhandleClose = () => {
    setCta(false);
  };
  return (
    <>
      <Wrapper>
        <div className=" overflow-x-hidden ">
          <Hero />
          <Info />
          <Programs />
          <Verticals />
          <Intro />
          <PlacementSupport />
          <Stats />
          <Suspense fallback={<>Loading...</>}>
            <Testimonials />
          </Suspense>
        </div>
      </Wrapper>
      <Rcta open={cta} handleClose={RcbhandleClose} />
    </>
  );
};

export default Home;
