import "./App.css";
import Home from "./Components/Home/Home";
import Faqs from "./Components/Faq/Subpage";
import { Suspense, lazy } from "react";
import SkeletonWL from "./Components/Skeleton/Withoutlogin";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import NotFound from "./Components/NotFound/NotFound";
import { Privacy, Referral, Terms, Admission, Cancellation, Refund } from "./pages/Policies";
import { Inclusive } from "./Components/Program";


// import Logout from "./Components/Login/Logout";
// import Webinar from "./pages/webinar/webinar";
// import Payment from "./pages/webinar/payment";
// import AuthLogin from "./Auth/AuthLogin";
// import AuthSignup from "./Auth/AuthSignup";
// import Lostpassword from "./Components/Home-password/Lostpassword";
// import Resetpassword from "./Components/Home-password/Resetpassword";
// import Datasciencebook from "./pages/ebook/datascienceebook";

const Successstories = lazy(() =>
  import("./Components/success-stories/success-stories")
);
const Whyinsaidhome = lazy(() =>
  import("./Components/WhyInsaid/Whyinsaidhome")
);

const Webinar = lazy(() => import("./pages/webinar/webinar"));
const Payment = lazy(() => import("./pages/webinar/payment"));
const AuthLogin = lazy(() => import("./Auth/AuthLogin"));
const AuthSignup = lazy(() => import("./Auth/AuthSignup"));
const Lostpassword = lazy(() => import("./Components/Home-password/Lostpassword"));
const Resetpassword = lazy(() => import("./Components/Home-password/Resetpassword"));
const Datasciencebook = lazy(() => import("./pages/ebook/datascienceebook"));
const Resourcescallback = lazy(() => import("./Resource/resource-callback"));
const Resourcescthankyou = lazy(() => import("./Resource/resource-thank-you"));
const OutlinedCard = lazy(() => import("./Components/Broucher/broucher"));
const Thankyou = lazy(() => import("./pages/webinar/thankyou-new"));

const VerticalDetail = lazy(() => import("./Components/Home/Vertical Page/VerticalsDetails"));
const MainVerticals = lazy(() => import("./Components/Home/Vertical Page/MainVerticals"));
const SearchedPage = lazy(() => import("./Components/Home/SearchedPage/SearchedPage"));
const Career = lazy(() => import("./Components/Careers/Career"));
const About = lazy(() => import("./Components/About/About"));
const Faq = lazy(() => import("./Components/Faq/MasterFaq"));
const Keymodule = lazy(() => import("./Resource/key-modules"));
const Factsheet = lazy(() => import("./Resource/fact-sheet"));
const Ebook = lazy(() => import("./Resource/ebook"));
const Gallery = lazy(() => import("./Components/Gallery/Gallery"));
const Programs = lazy(() => import("./Components/Programs/Programs"));
const CTAThankYou = lazy(() => import("./pages/cta-thank-you/cta-thank-you"));
const CTAThankYouVC = lazy(() => import("./pages/cta-thank-you/cta-thank-you-vc"));
const CTAThankYouScreeningCall = lazy(() => import("./pages/cta-thank-you/cta-thank-you-screening-call"))


const Master = lazy(() => import('./pages/Master Login/Master'))
const Mentor = lazy(() => import('./pages/MentorPage/Mentor'))
const MentorForm = lazy(() => import("./pages/MentorPage/MentorForm/MentorForm"))
const Refer = lazy(() => import("./Components/ReferAndEarn/Refer"))

const Redirect = () => {
  const { id } = useParams();
  return <Navigate to={`/programs/${id}`} replace />;
};

function App() {
  return (
    <>
      <Routes>
        {/* {localStorage.getItem('user_id')} */}
        {/* <Route path='/WordpressPasswordGenerator' element={<WordpressPasswordGenerator/>} />
        <Route path='/testing2' element={<PDFDragDrop/>} /> */}
        <Route path="*" element={<NotFound />} />
        <Route index path="/" element={<Home />} />
        <Route path="/Myaccount" element={<Navigate to={"/login"} />} />
        <Route path='/master' element={<Master />} />
        <Route path="/webinar/:id" element={<Suspense fallback={<SkeletonWL />}> <Webinar /> </Suspense>} />
        <Route path="/payment" element={
          <Suspense fallback={<SkeletonWL />}>
            <Payment />
          </Suspense>}
        />
        {/* <Route path="demo/:id" element={<YourComponent />} /> */}
        {/* filter page routes */}
        {/* <Route path="/programs" element={<SearchedPage />} /> */}
        <Route
          path="/programs"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <SearchedPage />
            </Suspense>
          }
        />
        {/* program pages routes */}
        <Route
          path="/programs/:id"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Programs />
            </Suspense>
          }
        />

        <Route>
          <Route
            path="/course/:category"
            element={
              <Suspense fallback={<SkeletonWL />}>
                {/* <VerticalDetail /> */}
                <MainVerticals />
              </Suspense>
            }
          />
        </Route>
        {/* <Route path="programs/:id" element={<Programs />} /> */}
        <Route path="program/inclusion/:id" element={<Inclusive />} />

        {/* admission policies routes */}
        <Route path="/policies/:id" element={<Admission />} />
        {/* terms and policies routes */}
        <Route path="/terms" element={<Terms />} />
        <Route path="/terms/privacy" element={<Privacy />} />
        <Route path="/terms/refund" element={<Refund />} />
        <Route path="/terms/cancellation" element={<Cancellation />} />
        <Route path="/terms/referral" element={<Referral />} />

        <Route
          path="/accredian/:id"
          element={<Redirect />}
        />
        <Route
          path="iit/:id"
          element={<Redirect />}
        />
        <Route
          path="iit-kanpur/:id"
          element={<Redirect />}
        />
        <Route
          path="imi/:id"
          element={<Redirect />}
        />
        <Route
          path="iim/:id"
          element={<Redirect />}
        />
        <Route
          path="iim-amritsar/:id"
          element={<Redirect />}
        />
        <Route
          path="XLRI/:id"
          element={<Redirect />}
        />
        <Route
          path="spj/:id"
          element={<Redirect />}
        />
        <Route
          path="/Career"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Career />
            </Suspense>
          }
        />

        <Route
          path="/Career/mentor"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Mentor />
            </Suspense>
          }
        />

        <Route
          path="/Career/mentor/application"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <MentorForm />
            </Suspense>
          }
        />

        <Route path="/datascience-ebook" element={<Suspense fallback={<SkeletonWL />}><Datasciencebook /></Suspense>} />
        <Route
          path="/About"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <About />
            </Suspense>
          }
        />
        <Route
          path="/Faq"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Faq />
            </Suspense>
          }
        />
        <Route
          path="/Successstories"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Successstories />
            </Suspense>
          }
        />
        <Route path="/Faqs/:id" element={<Faqs />} />
        <Route
          path="/whyaccredian"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Whyinsaidhome />
            </Suspense>
          }
        />
        <Route
          path="/Gallery"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Gallery />
            </Suspense>
          }
        />
        <Route
          path="/key-modules-resource/:id"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Keymodule />
            </Suspense>
          }
        />
        <Route
          path="/resource-fact-sheet/:id"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Factsheet />
            </Suspense>
          }
        />
        <Route
          path="/ebook"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Ebook />
            </Suspense>
          }
        />
        <Route
          path="/cta-thank-you"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <CTAThankYou />
            </Suspense>
          }
        />
        <Route
          path="/cta-thank-you-vc"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <CTAThankYouVC />
            </Suspense>
          }
        />
        <Route
          path="/cta-thank-you-screening-call"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <CTAThankYouScreeningCall />
            </Suspense>
          }
        />
        <Route
          path="/referandearn"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Refer />
            </Suspense>
          }
        />

        <Route path="/resource-callback" element={<Suspense fallback={<SkeletonWL />}> <Resourcescallback /></Suspense>} />
        <Route path="/resource-thank-you" element={<Suspense fallback={<SkeletonWL />}> <Resourcescthankyou /></Suspense>} />
        <Route path="/Login" element={<Suspense fallback={<SkeletonWL />}> <AuthLogin /></Suspense>} />
        {/* <Route path="/Logout" element={<Logout />} /> */}
        <Route path="/Signup" element={<Suspense fallback={<SkeletonWL />}> <AuthSignup /></Suspense>} />
        <Route path="/Lost-password" element={<Suspense fallback={<SkeletonWL />}> <Lostpassword /></Suspense>} />
        <Route path="/Reset-password/:id" element={<Suspense fallback={<SkeletonWL />}> <Resetpassword /></Suspense>} />

        <Route path="/brochure/:id" element={<Suspense fallback={<SkeletonWL />}><OutlinedCard /></Suspense>} />
        <Route path="/Thankyou" element={<Suspense fallback={<SkeletonWL />}><Thankyou /></Suspense>} />
      </Routes>
    </>
  );
}
export default App;
