import { Base64 } from 'js-base64';

export const encodeBase64 = (str) => {
    try {
      let encodedString = Base64.encode(str);
      return encodedString;
    } catch (e) {
      console.log("error", e);
      return null;
    }
  };