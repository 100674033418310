import React, { useEffect, useState } from 'react'
import { BiFilterAlt } from "react-icons/bi";
import ProgramCarousel from './Carousel/ProgramCarousel'
import "./Programs.css"
import { STREAMS } from '../../../Assets/constants/constants'
import FilterModal from '../../Modals/FilterModal/FilterModal';
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { programActions } from '../../../store/reducers/program';
import { useSelector } from 'react-redux';

import PROGRAMS from "../../../Assets/constants/programs.json"

const Programs = () => {

  const dispatch = useDispatch()
  const { checkedCategories } = useSelector(state => state.program)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isFullIteration, setIsFullIteration] = useState(false);
  const [filteredArray, setFilteredArray] = useState([])
  const [redirectUrl, setRedirectUrl] = useState('');

  const categories = [...new Set(PROGRAMS.map(item => item.category.toLowerCase()))];

  const limit = isFullIteration ? categories?.length : 2;

  const handleCategoryChange = (event) => {
    dispatch(programActions.setCheckedCategories(
      event.target.checked ? [...checkedCategories, event.target.value] : checkedCategories.filter(category => category !== event.target.value)
    ))
  };

  useEffect(() => {
    // --- for tags and categories combine ---
    // const programfilteredArray = PROGRAMS.filter(item => {
    //   return checkedCategories?.includes(item.category) || item.tags.some(tag => checkedTags.includes(tag))
    // }
    // );

    // --- for categories only ---
    const programfilteredArray = PROGRAMS.filter(item => {
      return checkedCategories?.includes(item.category.toLowerCase())
    });

    setFilteredArray(programfilteredArray)
  }, [checkedCategories])

  useEffect(() => {
    const queryParams = new URLSearchParams();

    if (checkedCategories.length) {
      queryParams.append('verticals', checkedCategories.join(','));
    }

    setRedirectUrl(queryParams.toString());
  }, [checkedCategories]);

  return (
    <div className='w-full min-h-screen flex justify-center mt-16 font-circular xl:px-12 px-4'>
      <div className='w-full max-w-[86rem] h-full flex flex-col gap-4'>
        <div className='w-full flex md:flex-row flex-col justify-normal md:justify-between px-[10px] lg:gap-8 gap-4 items-start md:items-end'>
          <div className='w-full'>
            <h4 className='text-base text-darkBlue font-medium max-sm:max-w-[300px]'>Checkout our courses</h4>
            <h1 className='text-3xl lg:text-[44px] mt-2 font-medium capitalize'>Advance Your Career with <span className='text-universal'>Industry Ready</span> Programs</h1>
          </div>
          <div className='flex gap-3 items-center md:flex-row flex-row-reverse md:w-fit w-full md:justify-center justify-between md:mt-0 mt-4'>
            <Link to={`/programs/?${redirectUrl}`} className='flex items-center gap-2 font-medium capitalize text-lg text-nowrap px-4 py-2 bg-universal/10 rounded-md cursor-pointer'>
              Show all
            </Link>
            <button className='flex items-center gap-2 font-medium capitalize text-lg px-4 py-2 bg-slate-300/40 rounded-md cursor-pointer text-nowrap' onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}>
              <BiFilterAlt />
              filters {checkedCategories.length > 0 && (`(${checkedCategories.length})`)}
            </button>
          </div>
        </div>
        <div className='programs-section w-full'>
          {filteredArray.length > 0 ? (
            <>
              {checkedCategories.map((vertical, index) => (
                <ProgramCarousel key={index} vertical={vertical} />
              ))}
            </>
          ) : (
            <>
              {STREAMS.slice(0, limit).map((vertical, index) => (
                <ProgramCarousel key={index} vertical={vertical.category} />
              ))}
            </>
          )}
        </div>
        {!filteredArray.length && (
          <div className='w-full flex justify-center flex-col py-4 px-6 items-center'>
            <IoIosArrowDown className={` ${isFullIteration ? "block" : "hidden"} rotate-180 text-universal`} />
            <div className='w-full flex items-center'>
              <div className='flex-1 border-b border-neutral-300' />
              <button className='flex-[0.2] px-4 py-2 text-nowrap rounded-md text-slate-500 hover:text-darkBlue text-xl font-medium font-circular flex flex-col items-center' onClick={() => setIsFullIteration(!isFullIteration)}>
                {isFullIteration ? "View less courses" : "View more courses"}
              </button>
              <div className='flex-1 border-b border-neutral-300' />
            </div>
            <IoIosArrowDown className={` ${isFullIteration ? "hidden" : "block"} text-universal animate-bounce`} />
          </div>
        )}
      </div>
      <FilterModal
        categories={categories}
        open={isFilterModalOpen}
        setOpen={setIsFilterModalOpen}
        handleChange={handleCategoryChange}
        setChecked={programActions.setCheckedCategories}
        checked={checkedCategories}
        title={"Verticals"}
      />
    </div>
  )
}

export default Programs