import React from 'react'
import Footer from '../Footer/Footer'
import { MainNavbar } from '../Navbar'

const Wrapper = ({ children }) => {
  return (
    <>
      <MainNavbar />
      {/* <div className='h-56 w-full bg-blue-400' /> */}
      {children}
      <Footer />
    </>
  )
}

export default Wrapper