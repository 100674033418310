import React, { useEffect, useRef } from 'react';
import { Power0, gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { AiOutlineRise } from "react-icons/ai";
// import { SiAcclaim } from "react-icons/si";
// import { GrStatusGood } from "react-icons/gr";

gsap.registerPlugin(ScrollTrigger);

const Stats = () => {
  const showStats = useRef()
  useEffect(() => {

    const cx_stats = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.start-from-zero',
          start: 'top 95%',
          // markers: true
        },
      })
      tl.from('.start-from-zero', {
        textContent: 0,
        duration: 2,
        ease: Power0,
        snap: {
          textContent: 1
        },
      })

    }, showStats)

    return () => {
      cx_stats.revert()
    }
  }, [])


  return (
    <div ref={showStats} className='w-full flex justify-center items-center font-circular px-4 bg-slate-200/40'>
      <div className='max-w-[85rem] w-full rounded-xl py-[54px] px-8 text-center'>
        <h1 className='text-3xl md:text-4xl font-medium'>Our numbers speak for themselves!</h1>
        <div className='flex justify-center flex-wrap gap-4 mt-16'>
          <div className='px-4 text-center rounded-lg max-w-sm w-full flex flex-col items-center'>
            <div className='flex items-center gap-2 justify-normal'>
              {/* <AiOutlineRise className='text-4xl' /> */}
              <h2 className='text-4xl font-medium text-universal'><span className='start-from-zero'>150</span>%</h2>
            </div>
            <h3 className='text-sm mt-2'>Highest Salary Hike</h3>
            <h1 className='mt-3 text-sm text-neutral-500 max-w-[260px]'>Experience substantial salary growth with our competitive compensation packages.</h1>
          </div>
          <div className='px-4 text-center rounded-lg max-w-sm w-full flex flex-col items-center'>
            <div className='flex items-center gap-2 justify-normal'>
              {/* <GrStatusGood className='text-3xl' /> */}
              <h2 className='text-4xl font-medium text-universal'><span className='start-from-zero'>60</span>%</h2>
            </div>
            <h3 className='text-sm mt-1'>Average Salary Hike</h3>
            <h1 className='mt-3 text-sm text-neutral-500 max-w-[260px]'>Unlock consistent salary growth through our rewarding career paths.</h1>
          </div>
          <div className='px-4 text-center rounded-lg max-w-sm w-full flex flex-col items-center'>
            <div className='flex items-center gap-2 justify-normal'>
              {/* <SiAcclaim className='text-3xl' /> */}
              <h2 className='text-4xl font-medium text-universal'><span className='start-from-zero'>80</span>%</h2>
            </div>
            <h3 className='text-sm mt-1'>Positive Career Impact</h3>
            <h1 className='mt-3 text-sm text-neutral-500 max-w-[260px]'>Elevate your career trajectory with positive, impactful opportunities.</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stats;
