import { SiGoogleclassroom } from "react-icons/si";
// import { FcWorkflow } from "react-icons/fc";
// import { SiGooglebigquery } from "react-icons/si";
import { BiSupport } from "react-icons/bi";
import { GoGoal } from "react-icons/go";

const Intro = () => {

  return (
    <div className="w-full flex items-center justify-center py-8 px-4 font-circular xl:px-12">
      <div className="xl:max-w-[80rem] 2xl:max-w-[85rem] w-full py-2">
        <h4 className='text-base text-darkBlue font-medium max-sm:max-w-[300px]'>Customized Learning Solutions</h4>
        <h1 className='text-3xl md:text-[44px] mt-2 font-medium capitalize'>Career Growth through Experience</h1>
        <div className="w-full flex lg:flex-row flex-col gap-4 2xl:gap-8 justify-between py-4 mt-8">
          <div className="lg:max-w-[22rem] 2xl:max-w-sm w-full">
            <SiGoogleclassroom className="text-[50px] lg:text-[5vw] text-universal" />
            <h1 className="font-semibold text-base md:text-lg lg:text-xl mt-4">Access education through convenient online classes</h1>
            <h2 className="font-normal mt-2 text-sm md:text-base lg:text-lg">Engage in live, instructor-led sessions every weekend, unfolding knowledge and skills, exclusively on Saturdays and Sundays</h2>
          </div>
          <div className="lg:max-w-[22rem] 2xl:max-w-sm w-full">
            <GoGoal className="text-[50px] lg:text-[5vw] text-universal" />
            <h1 className="font-semibold text-base md:text-lg lg:text-xl mt-4">Consistently practice on our platform daily</h1>
            <h2 className="font-normal mt-2 text-sm md:text-base lg:text-lg">Engage in hands-on learning through assignments and real-world projects to enhance your skills and gain practical experience</h2>
          </div>
          <div className="lg:max-w-[22rem] 2xl:max-w-sm w-full">
            <BiSupport className="text-[50px] lg:text-[5vw] text-universal" />
            <h1 className="font-semibold text-base md:text-lg lg:text-xl mt-4">Promptly seek clarification by asking questions</h1>
            <h2 className="font-normal mt-2 text-sm md:text-base lg:text-lg">Take advantage of our professional technical support services, available around the clock for any assistance you may need</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro