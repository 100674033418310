import React from "react";
import "./Info.css";

const Info = () => {
  return (
    <div
      id="courses"
      className="bg-neutral-100/75 py-12 flex flex-col items-center px-4"
    >
      <h4 className="text-center font-medium text-neutral-600 text-lg">
        Our alumni hold prominent positions in various industries.
      </h4>
      <div className="mt-4 w-full max-w-7xl flex flex-wrap justify-center items-center lg:gap-16 xl:gap-28 gap-8">
        <div className="max-w-[80px] md:max-w-[120px] md:h-20 flex justify-center items-center w-full">
          <img
            loading="lazy"
            className="w-full h-full object-contain"
            src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/microsoft.svg"
            alt="microsoft"
          />
        </div>
        <div className="max-w-[80px] md:max-w-[120px] md:h-20 flex justify-center items-center w-full">
          <img
            loading="lazy"
            className="w-full h-full object-contain md:scale-125"
            src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/Google.svg"
            alt="Google"
          />
        </div>
        <div className="max-w-[80px] md:max-w-[120px] md:h-20 hidden md:flex justify-center items-center w-full">
          <img
            loading="lazy"
            className="w-full h-full object-contain"
            src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/flipkart.webp"
            alt="flipkart"
          />
        </div>
        <div className="max-w-[80px] md:max-w-[120px] md:h-20 flex justify-center items-center w-full">
          <img
            loading="lazy"
            className="w-full h-full object-contain"
            src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/amazon.webp"
            alt="amazon"
          />
        </div>
        <div className="max-w-[80px] md:max-w-[120px] md:h-20 hidden md:flex justify-center items-center w-full pb-8">
          <img
            loading="lazy"
            className="w-full h-full object-contain"
            src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accenture.webp"
            alt="accenture"
          />
        </div>
      </div>
    </div>
  );
};

export default Info;
