import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function SkeletonWL() {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}
      {/* For other variants, adjust the size with `width` and `height` */}
      {/* <Skeleton variant="circular" width={40} height={40} /> */}
      <Skeleton style={{marginBottom:"30px"}} variant="rectangular" width="auto" height={80} />
      <Skeleton style={{margin:"10px 35px"}} variant="rounded" width="95%" height={500} />
    </Stack>
  );
}