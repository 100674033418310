import { ChakraProvider, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import ExploreDrawerChild from './ExploreDrawerChild';
import ExploreDrawerParent from './ExploreDrawerParent';

const ExploreDrawer = ({ isOpen, onClose }) => {

  const [streamWithTab, setStreamWithTab] = useState("")

  const { isOpen: innerIsOpen, onOpen: innerOnOpen, onClose: innerOnClose } = useDisclosure()

  const handleInnerOpen = (tab) => {
    if (tab) {
      setStreamWithTab(tab)
      innerOnOpen()
    }
  }

  const handleCloseAll = () => {
    innerOnClose()
    onClose()
  }

  return (
    <>
      <ChakraProvider>
        {!innerIsOpen && (
          <ExploreDrawerParent onClose={onClose} isOpen={isOpen} handleInnerOpen={handleInnerOpen} />
        )}
        <ExploreDrawerChild innerIsOpen={innerIsOpen} innerOnClose={innerOnClose} handleCloseAll={handleCloseAll} streamWithTab={streamWithTab} />
      </ChakraProvider>
    </>
  )
}

export default ExploreDrawer